import React from "react";

export default (props) => {
  return (
    <div className='supporter-logos'>
      <div className='logos-container'>
        <div className='logos-container__logo'>
          <img className='folklife' src='/images/interface/logos/txf_logo_stacked.jpg' alt='Texas Folklife logo' />
        </div>
        <div className='logos-container__logo'>
          <img className='cultural-arts' src='/images/interface/logos/COA_CA_hz_fc_300.png' alt='City of Austin Cultural Arts logo' />
        </div>
      </div>
      <p>
        <b>{props.siteTitle}</b> is supported in part by Texas Folklife and a grant from the City of Austin Cultural Arts Division.
      </p>
    </div>
  );
};