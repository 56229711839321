import React from 'react';
import { Link } from 'gatsby';

const breakOutExhibitData = (reference, exhibitEdges) => {
  const data = {
    title: '',
    subtitle: '',
    imgPath: '',
    imgAlt: '',
  };
  const referencedExhibit = exhibitEdges.filter(({ node }) => node.reference === reference);

  if (referencedExhibit.length > 0) {
    data.title = referencedExhibit[0].node.short_title;
    data.subtitle = referencedExhibit[0].node.subtitle;
    data.imgPath = referencedExhibit[0].node.image.path;
    data.imgAlt = referencedExhibit[0].node.image.alt;
  }

  return data;
};

const HomepageExhibitCell = (props) => {
  const {
    title,
    subtitle,
    imgPath,
    imgAlt,
  } = breakOutExhibitData(props.reference, props.exhibitEdges);

  return (
    <div className={`homepage-display-grid__exhibit-cell homepage-display-grid__${props.reference}`}>
      <Link to={`/${props.reference}`}>
        <div className="image-wrapper">
          <img src={`/images/${imgPath}`} alt={imgAlt} />
        </div>
        <div className="title-block">
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
        </div>
      </Link>
    </div>
  );
};

export default HomepageExhibitCell;