import React from "react";
import { marked } from 'marked';
import { Link, graphql } from "gatsby";
import HomepageLayout from "../components/layout/layout-homepage";

import PageSection from "../components/layout/page-section";
import SupporterLogos from "../components/layout/supporter-logos";
import HomepageExhibitCell from "../components/homepage/homepage-exhibit-cell";

import FullSignupForm from "../components/newsletter/full-signup-form";

const exhibitTitle = (reference, exhibitEdges) => {
  const referencedExhibit = exhibitEdges.filter(({ node }) => node.reference === reference);

  if (referencedExhibit.length > 0) {
    return referencedExhibit[0].node.short_title;
  }

  return '';
};

const feedLinks = (narrativeEdges, blogEdges, exhibitEdges) => {
  let dateChunks = [];

  // Construct narrative links
  let narrativeLinks = narrativeEdges.map(({ node }) => {
    return {
      sortDate: node.updated ? node.updated : node.published,
      dateType: node.updated ? 'Updated': 'Published',
      order: node.order,
      title: node.title,
      summary: node.summary,
      url: `/${node.exhibit}/narratives/${node.reference}`,
      category: `Exhibit: ${exhibitTitle(node.exhibit, exhibitEdges)}`,
    };
  });
  // Construct blog links
  let blogLinks = blogEdges.map(({ node }) => {
    return {
      sortDate: node.frontmatter.date,
      dateType: 'Published',
      title: node.frontmatter.title,
      summary: node.frontmatter.summary,
      url: `/notes${node.fields.slug}`,
      category: 'Notes from a Limestone Ledge',
    };
  });

  // Mix blog and narrative links
  let feedLinks = narrativeLinks.concat(blogLinks);

  // Build chunks of links grouped by date
  feedLinks.forEach(link => {
    const filteredDates = dateChunks.filter(chunk => chunk.sortDate === link.sortDate);

    if (filteredDates.length === 0) {
      dateChunks.push({ sortDate: link.sortDate, links: [link] });
    } else {
      dateChunks.forEach(chunk => {
        if (chunk.sortDate === link.sortDate) {
          chunk.links.push(link);
        }
      });
    }
  });

  // Sort date chunks
  dateChunks = dateChunks.sort((a, b) => {
    if (a.sortDate > b.sortDate) {
      return -1;
    } else if (a.sortDate < b.sortDate) {
      return 1;
    }

    return 0;
  });

  dateChunks = dateChunks.slice(0,3);

  // Sort links within dateChunks
  dateChunks.forEach(chunk => {
    chunk.links = chunk.links.sort((a, b) => {
      // Sort blog posts to top
      if (a.category === 'Blog') {
        return -1;
      } else if (b.category === 'Blog') {
        return 1;
      } else {
        if (a.order < b.order) {
          return -1;
        } else if (a.order > b.order) {
          return 1;
        }
      }

      return 0;
    })
  });

  return dateChunks.map(chunk => (
    <div className="homepage-feed__date-block">
      <p className="homepage-feed__dateline">
        <span className="contents">
          {new Date(chunk.sortDate + " 12:00").toLocaleDateString(
            'en-us',
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }
          )}
        </span>
      </p>
      <ul>
        {chunk.links.map((link, idx) => (
          <li key={`homepage-feed-${chunk.sortDate}-${idx}`}>
            <p className="homepage-feed__header">
              <Link
                to={ link.url }
                className="title"
                dangerouslySetInnerHTML={ { __html: marked(link.title).replace('<p>', '').replace('</p>','') } }
              />
              <span className="category-tag">{ link.category }</span>
            </p>
            <p className="homepage-feed__summary">
              {link.summary}{' '}
              <Link to={ link.url } className="heavy">Read more…</Link>
            </p>
          </li>
        ))}
      </ul>
    </div>
  ));
};

export default ({ data }) => (
  <HomepageLayout>
    <div className="homepage-special-layout">
      <PageSection background="white" wrapperType="wrapper">
        <div className="homepage-display-grid">
          <div className="homepage-display-grid__grid">
            <div className="homepage-display-grid__awn">
              <img
                className="poster"
                src="/images/interface/homepage/kerry-awn-poster.png"
                alt="Hand-drawn poster by Kerry Awn depicting notable musicians and music venues from Austin"
              />
              <div className="homepage-display-grid__intro">
                <p>
                  <b>{data.site.siteMetadata.short_title}</b> is an ongoing public history project presenting a cultural geography of music performance in Austin, Texas. <Link className="small-text heavy" to="/about">Learn more…</Link>
                </p>
              </div>
            </div>
            <div className="homepage-display-grid__header">
              Exhibits
            </div>
            <HomepageExhibitCell
              reference="athens-on-the-colorado"
              exhibitEdges={ data.allExhibitsJson.edges }
            />
            <HomepageExhibitCell
              reference="the-rise-of-the-honky-tonks"
              exhibitEdges={ data.allExhibitsJson.edges }
            />
          </div>
        </div>
      </PageSection>

      <PageSection background="gray" topPadding="md">
          <h2 className="homepage-notes__header">
            Recent Updates
          </h2>
      </PageSection>
      <PageSection background="gray" topPadding="sm" bottomPadding="md">
        <div className="homepage-feed">
          {feedLinks(data.allNarrativesJson.edges, data.allPosts.edges, data.allExhibitsJson.edges)}
        </div>
      </PageSection>

      <PageSection wrapperType="wrapper" topPadding="md" bottomPadding="md">
        <div className="homepage__content-block content-width">
          <div className="homepage-big-button-grid">
            <div className="homepage-big-button-grid__cell big-button big-button__artists">
              <Link to="/artists">
                <span className="label">Browse Artists</span>
              </Link>
            </div>
            <div className="homepage-big-button-grid__cell big-button big-button__venues">
              <Link to="/venues"><span className="label">Browse Venues</span></Link>
            </div>
          </div>
        </div>
      </PageSection>

      <PageSection wrapperType="wrapper" bottomPadding="md">
        <h2 className="homepage__header">
          Browse Performances
        </h2>
        <div className="homepage__content-block content-width">
          <div className="homepage-browse-view-grid">
            <div className="homepage-browse-view-grid__cell">
              <Link to="/performances/artists">By Artist</Link>
            </div>
            <div className="homepage-browse-view-grid__cell">
              <Link to="/performances/venues">By Venue</Link>
            </div>
            <div className="homepage-browse-view-grid__cell">
              <Link to="/performances/years">By Year</Link>
            </div>
          </div>
        </div>
      </PageSection>

      <PageSection wrapperType="wrapper" bottomPadding="lg">
        <h2 className="homepage__header">
          Browse Images
        </h2>
        <div className="homepage__content-block content-width">
          <div className="homepage-browse-view-grid">
            <div className="homepage-browse-view-grid__cell">
              <Link to="/images/artists">By Artist</Link>
            </div>
            <div className="homepage-browse-view-grid__cell">
              <Link to="/images/venues">By Venue</Link>
            </div>
            <div className="homepage-browse-view-grid__cell">
              <Link to="/images/years">By Year</Link>
            </div>
          </div>
        </div>
      </PageSection>

      <PageSection background="gray" topPadding="md" bottomPadding="md">
        <FullSignupForm />
      </PageSection>

      <PageSection bottomPadding="md">
        <div className="homepage__content-block content-width">
          <SupporterLogos siteTitle={data.site.siteMetadata.title} />
        </div>
      </PageSection>
    </div>
  </HomepageLayout>
)

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        short_title
      }
    }
    allPosts: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: { fields: {slug: {ne: null}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            summary
          }
          fields {
            slug
          }
        }
      }
    }
    recentPosts: allMarkdownRemark(
      limit: 3
      sort: {fields: frontmatter___date, order: DESC}
      filter: {fields: {slug: {ne: null}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            summary
          }
          fields {
            slug
          }
        }
      }
    }
    allExhibitsJson {
      edges {
        node {
          id
          reference
          long_title
          short_title
          subtitle
          image {
            path
            alt
          }
        }
      }
    }
    allNarrativesJson {
      edges {
        node {
          exhibit
          reference
          title
          summary
          published
          updated
          order
        }
      }
    }
  }
`
