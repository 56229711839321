import React from "react";

import "../../scss/components/newsletter.scss";

class FullSignupForm extends React.Component {
  render() {
    return (
      <div id="newsletter-signup" className="newsletter-block">
        <h2>
          Sign Up for the Newsletter
        </h2>
        <p>
          Keep up-to-date with new content and other news about the <i>Local Memory</i> project.
        </p>
        <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
        <div id="mc_embed_signup">
          <form action="https://github.us19.list-manage.com/subscribe/post?u=416a99f21246e097b2f0a7279&amp;id=b0e829418b" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span></label>
                <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">First Name </label>
                <input type="text" defaultValue="" name="FNAME" className="" id="mce-FNAME" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">Last Name </label>
                <input type="text" defaultValue="" name="LNAME" className="" id="mce-LNAME" />
              </div>
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{ display: "none" }} />
                <div className="response" id="mce-success-response" style={{ display: "none" }} />
              </div>
              <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_416a99f21246e097b2f0a7279_b0e829418b" tabIndex="-1" defaultValue="" /></div>
              <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FullSignupForm;